<template>
  <th-page-wrapper>
    <th-drawer ref="edit" v-model="isModalOpen">
      <receipt-viewer
        v-if="currentRow.id"
        :key="currentRow.id"
        :receipts="currentRow.receipts"
        :receipt-title="currentRow.id"
        :resource-id="currentRow.id"
        @close-receipts="closeReceiptViewer"
        @cancel-requested="closeReceiptViewer"
      />
    </th-drawer>

    <th-datatable
      ref="table"
      sortable
      :headers="headers"
      :show-operations="false"
      :resource-limit="1000"
      resource="transactions"
      :custom-resource="customResource"
      :resource-query="resourceQuery"
      :locale="locale"
      no-meta-check
      expanding-row
      show-filter
      :search-filters="filtersList"
      route-base="/reports/financial_accounting/returns"
      do-route-filters
      headers-filterable
      :headers-config="headersConfig"
      :headers-default-hide="[
        'branch_custom_id',
        'register_custom_id',
        'customer_name',
        'customer_number'
      ]"
      :search-filter-config="{ allowTextInput: false }"
      prune-search-filters
      @loading-error="handleLoadingError"
      @headers-config="handleHeadersConfig"
    >
      <template #expanding-row="{ row }">
        <expanding-row :row="row" @open-current-receipts="handleOpenReceipts" />
      </template>
    </th-datatable>
  </th-page-wrapper>
</template>

<script>
import th from '@tillhub/javascript-sdk'
import qs from 'qs'
import pick from 'just-pick'
import safeGet from 'just-safe-get'
import { mapGetters } from 'vuex'
import ExpandingRow from './components/expanding-row'
import ReceiptViewer from '../../../../components/tillhub/receipt-viewer'
import { applyFiltersBeforeRouteEnter, getRangeFor } from '@/utils/date'

const IS_LEGACY = true

export default {
  metaInfo() {
    return {
      title: this.$t('reports.financial_accounting.returns')
    }
  },
  components: {
    ExpandingRow,
    ReceiptViewer
  },
  beforeRouteEnter: (to, from, next) => {
    // doing stuff here is very dangerous as it might lead to infinite route loops
    applyFiltersBeforeRouteEnter({ path: to.path, query: to.query, next })
  },
  beforeRouteUpdate(to, from, next) {
    // as UX enhancement we are going to persist some of the queries
    const elegibleObj = pick(safeGet(qs.parse(to.query), 'filter') || {}, [
      'date',
      'branch_group'
    ])
    this.$emit('route-filter', elegibleObj)

    next()
  },
  props: {
    resources: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      currentRow: {
        receipts: [],
        id: null
      },
      isModalOpen: false,
      headers: [
        {
          field: 'date',
          label: this.$t('pages.transactions.all.table.date'),
          minWidth: 150,
          truncate: true,
          formatter: (row) => {
            if (!row.date) return '--'
            return this.$date.formatDateTimeWithTimezone(row.date)
          },
          sortType: 'date'
        },
        {
          field: 'receipt_number',
          label: this.$t('pages.transactions.all.table.receipt_number'),
          width: 150,
          truncate: true,
          formatter: (row, column) => {
            return row.custom_id
          },
          sortType: 'number'
        },
        {
          field: 'staff_custom_id',
          label: this.$t('pages.transactions.all.table.staff'),
          width: 150,
          truncate: true,
          formatter: (row, column) => {
            const staff = safeGet(row, 'context.cashier_staff')
            return this.getStaffName(staff)
          },
          sortType: 'number'
        },
        {
          field: 'branch_custom_id',
          label: this.$t('pages.transactions.all.table.branch_custom_id'),
          minWidth: 120,
          truncate: true,
          formatter: (row, column) => {
            return row.branch_custom_id
          },
          sortType: 'number'
        },
        {
          field: 'register_custom_id',
          label: this.$t('pages.transactions.all.table.register_custom_id'),
          minWidth: 120,
          truncate: true,
          formatter: (row, column) => {
            return row.register_custom_id
          },
          sortType: 'number'
        },
        {
          field: 'item_count',
          label: this.$t('pages.transactions.all.table.item_count'),
          align: 'right',
          minWidth: 120,
          truncate: true,
          formatter: (row, column) => {
            const returnItems = this.getReturnItems(row)
            return returnItems.reduce((acc, item) => acc + item.qty, 0)
          },
          sortType: 'number'
        },
        {
          field: 'total_gross',
          label: this.$t('pages.transactions.all.table.amount_total_gross'),
          minWidth: 150,
          truncate: true,
          formatter: (row, column) => {
            const returnItems = this.getReturnItems(row)
            const sum = returnItems.reduce(
              (acc, item) => acc + item.amount_total_gross,
              0
            )
            return this.$formatCurrency(sum, returnItems[0].currency) // NOTE: this is where multi-currency needs to be handled in the new tx model
          },
          align: 'right',
          sortType: 'currency'
        },
        {
          field: 'related_transaction',
          label: this.$t('pages.returns.all.table.related_transaction'),
          minWidth: 170,
          truncate: true,
          formatter: (row, column) => {
            const returnItems = this.getReturnItems(row)
            const result = returnItems.reduce((acc, item) => {
              if (IS_LEGACY && item.related_transaction_number) {
                acc.add(item.related_transaction_number)
              } else if (item.depends_on && Array.isArray(item.depends_on)) {
                item.depends_on.forEach((d) => {
                  d.custom_id && acc.add(d.custom_id)
                })
              }
              return acc
            }, new Set())
            return Array.from(result).join(', ') || null
          },
          sortType: 'number'
        },
        {
          field: 'customer_name',
          label: this.$t('pages.transactions.all.table.customer_name'),
          fallback: '-',
          minWidth: 150,
          truncate: true,
          formatter: (row, column) => {
            const firstname = safeGet(row, 'customer.firstname')
            const lastname = safeGet(row, 'customer.lastname')

            if (firstname || lastname) {
              return `${firstname || ''}${firstname && lastname ? ' ' : ''}${
                lastname || ''
              }`
            } else {
              return '--'
            }
          }
        },
        {
          field: 'customer_number',
          label: this.$t('pages.transactions.all.table.customer_number'),
          minWidth: 160,
          truncate: true,
          formatter: (row, column) => {
            return safeGet(row, 'customer.customer_number')
          }
        },
        {
          field: 'type',
          label: this.$t('common.headers.type.title'),
          minWidth: 100,
          truncate: true,
          formatter: (row, column) => {
            return this.getReturnType(row)
          }
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      currentLocation: 'Config/getCurrentLocation',
      locale: 'Config/getLocale',
      localConfiguration: 'Config/getLocalConfiguration',
      defaultDateSelected: 'Config/getDefaultDateSelected'
    }),

    filtersList() {
      const transactionTypes = [
        {
          value: 'sale_cancel',
          label: this.$t('common.transactions.types.sale_cancel')
        },
        {
          value: 'refund',
          label: this.$t('common.transactions.types.refund')
        },
        {
          value: 'potential_refund',
          label: this.$t('common.transactions.types.potential_refund')
        }
      ]
      return [
        {
          name: 'receipt_number',
          type: 'input',
          label: this.$t('pages.transactions.all.filters.receipt_number.label'),
          placeholder: this.$t('common.inputs.placeholders.custom_id')
        },
        {
          name: 'type',
          type: 'select',
          label: this.$t('common.headers.type.title'),
          placeholder: this.$t('common.inputs.placeholders.select'),
          options: transactionTypes
        },
        {
          name: 'branch_group',
          type: 'remote-search-select',
          doInitialFetch: true,
          label: this.$t('pages.reports.statistics.all.branch_group'),
          resource: 'branchGroups',
          filterable: true,
          optionsValue: 'id',
          disabled: !!this.currentLocation,
          computeName: this.$formatBranch,
          modifyQuery: (q) => ({
            q,
            deleted: false
          })
        },
        {
          name: 'date',
          prop: ['date_start', 'date_end'],
          type: 'daterange',
          label: this.$t('pages.transactions.all.filters.date.label'),
          closable: false,
          noFutureDates: true,
          formatValue: (value) => this.$date.formatDateRange(value),
          default: getRangeFor[this.defaultDateSelected]?.(),
          modifyFilter: (filterObject) => ({
            date_start: filterObject.start,
            date_end: filterObject.end
          })
        }
      ]
    },
    headersConfig() {
      return safeGet(
        this.localConfiguration,
        'settings.headerFilters.returns',
        {}
      )
    },
    customResource() {
      return th.transactions({ timeout: 90000 })
    },
    resourceQuery() {
      return {
        legacy: IS_LEGACY,
        type: ['refund', 'potential_refund', 'sale_cancel'],
        branch: this.currentLocation || undefined
      }
    }
  },
  mounted() {
    this.$emitter.on('refresh-requested', () => {
      this.$refs.table.refresh()
    })
  },
  beforeUnmount() {
    this.$emitter.off('refresh-requested')
  },
  methods: {
    handleLoadingError(err) {
      this.$logException(err, {
        trackError: false,
        message: this.$t('common.error.action.read.multiple', {
          resources: this.$t('pages.transactions.title')
        })
      })
    },
    getReturnType(row) {
      if (!row.type) {
        return null
      } else if (row.type === 'sale_cancel') {
        return this.$t('common.transactions.types.sale_cancel')
      } else if (row.type === 'sale') {
        const hasRefund =
          row.items && row.items.some((i) => i.type === 'refund')
        const hasPotentialRefund = row.items && row.items.some((i) => i.qty < 0)

        if (hasRefund) {
          return this.$t('common.transactions.types.refund')
        } else if (hasPotentialRefund) {
          return this.$t('common.transactions.types.potential_refund')
        } else {
          return this.$t('common.transactions.types.sale')
        }
      } else {
        return row.type
      }
    },
    handleHeadersConfig(config) {
      this.$store.dispatch('Config/setLocalConfigurationValue', {
        path: 'settings.headerFilters.returns',
        value: config || {}
      })
    },
    getReturnItems(row) {
      const { items = [] } = row

      return items.filter((item) => {
        return (
          row.type === 'sale_cancel' || item.type === 'refund' || item.qty < 0
        )
      })
    },
    getStaffName(item) {
      return this.$formatStaff(item) || '--'
    },
    handleOpenReceipts(row) {
      this.currentRow.id = row.resourceId
      this.currentRow.receipts = row.receipts
      this.isModalOpen = true
    },
    closeReceiptViewer() {
      this.isModalOpen = false
    }
  }
}
</script>

<style scoped></style>
