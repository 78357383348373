<template>
  <div>
    <div
      v-if="(row.type === 'sale' || row.type === 'sale_cancel') && row.items"
    >
      <el-row :gutter="20">
        <el-col :span="20">
          <!-- Items -->
          <el-row class="font-bold">
            <el-col :span="8">
              <el-row v-for="item in row.items" :key="item.id" class="mb-1">
                <el-col :span="4" v-text="getQuantity(item.qty)" />
                <el-col :span="20" v-text="item.name || '--'" />
              </el-row>
            </el-col>
            <el-col :span="8">
              <div v-for="item in row.items" :key="item.id" class="mb-1">
                <span>
                  {{ localizeCurrency(item.amount_unit_gross, item.currency) }}
                </span>
                <span class="vat-rate">
                  {{ (item.vat_rate || 0).toLocaleString(locale) }}%
                </span>
              </div>
            </el-col>
            <el-col :span="8" class="text-right">
              <div
                v-for="item in row.items"
                :key="item.id"
                class="mb-1"
                v-text="
                  localizeCurrency(item.amount_total_gross, item.currency)
                "
              />
            </el-col>
          </el-row>

          <!-- Totals -->
          <el-row class="sub-table-row">
            <el-col :span="8" :offset="8">
              <div
                class="mb-1"
                v-text="
                  $t('pages.transactions.all.table.subtable.total_discounts')
                "
              />
              <div
                class="mb-1"
                v-text="$t('pages.transactions.all.table.subtable.total_tax')"
              />
              <div
                class="font-bold"
                v-text="
                  $t(
                    'pages.transactions.all.table.subtable.total'
                  ).toUpperCase()
                "
              />
            </el-col>
            <el-col :span="8" class="text-right">
              <div class="mb-1" v-text="getTotal('discount_amount_total')" />
              <div class="mb-1" v-text="getTotal('tax_amount_total')" />
              <div
                class="mb-1 font-bold"
                v-text="getTotal('amount_total_gross')"
              />
            </el-col>
          </el-row>

          <el-row
            v-if="row.payments && row.payments.length"
            class="sub-table-row"
          >
            <el-col :span="8" :offset="8">
              <div
                v-for="payment in row.payments"
                :key="payment.id"
                class="mb-1"
                v-text="paymentTypeMap[payment.type]"
              />
              <div
                v-if="row.change"
                v-text="$t('pages.transactions.all.table.subtable.change')"
              />
            </el-col>
            <el-col :span="8" class="text-right">
              <div
                v-for="payment in row.payments"
                :key="payment.id"
                class="mb-1"
                v-text="
                  localizeCurrency(payment.amount_total, payment.currency)
                "
              />
              <div
                v-if="row.change"
                v-text="localizeCurrency(row.change, row.currency)"
              />
            </el-col>
          </el-row>
        </el-col>

        <!-- Actions -->
        <el-col :span="4" class="actions-column">
          <el-button
            text
            class="button-mini"
            :disabled="!row.receipts || !row.receipts.length"
            @click="openReceiptViewer"
            v-text="
              $t('pages.transactions.all.table.subtable.actions.show_receipt')
            "
          />
          <el-button
            text
            class="button-mini"
            :disabled="!row.receipts || !row.receipts.length"
            @click="openReceiptEmailer"
            v-text="
              $t('pages.transactions.all.table.subtable.actions.email_receipt')
            "
          />
          <el-button
            text
            class="button-mini"
            disabled
            v-text="
              $t('pages.transactions.all.table.subtable.actions.print_receipt')
            "
          />
          <el-button
            text
            class="button-mini"
            disabled
            v-text="
              $t(
                'pages.transactions.all.table.subtable.actions.print_quittance'
              )
            "
          />
        </el-col>
      </el-row>
    </div>
    <div
      v-else
      v-text="$t('pages.transactions.all.table.subtable.no_cartitems')"
    />
    <Teleport to="#th-modals">
      <receipt-emailer
        :open="receiptEmailerOpen"
        :receipts="row.receipts"
        :cart="getCart()"
        :lines="getLines()"
        :date="row.date"
        :types="['customer']"
        :show-search="false"
        @close-emailer="closeReceiptEmailer"
        @cancel-emailer="closeReceiptEmailer"
        @email-sent="closeReceiptEmailer"
      />
    </Teleport>
  </div>
</template>

<script>
import pick from 'just-pick'
import safeGet from 'just-safe-get'
import ReceiptEmailer from '../../../../../components/tillhub/receipt-emailer'

export default {
  components: {
    ReceiptEmailer
  },
  props: {
    row: {
      type: Object,
      required: false,
      default: undefined
    },
    rawRow: {
      type: Object,
      required: false,
      default: undefined
    }
  },
  data() {
    return {
      receiptViewerOpen: false,
      receiptEmailerOpen: false,
      paymentTypeMap: {
        card: this.$t(
          'pages.transactions.all.table.subtable.payment_types.card'
        ),
        cash: this.$t(
          'pages.transactions.all.table.subtable.payment_types.cash'
        ),
        expense: this.$t(
          'pages.transactions.all.table.subtable.payment_types.expense'
        ),
        voucher: this.$t(
          'pages.transactions.all.table.subtable.payment_types.voucher'
        ),
        custom: this.$t(
          'pages.transactions.all.table.subtable.payment_types.custom'
        ),
        invoice: this.$t(
          'pages.transactions.all.table.subtable.payment_types.invoice'
        ),
        card_opi: this.$t(
          'pages.transactions.all.table.subtable.payment_types.card_opi'
        ),
        card_concardis: this.$t(
          'pages.transactions.all.table.subtable.payment_types.card_concardis'
        ),
        card_adyen: this.$t(
          'pages.transactions.all.table.subtable.payment_types.card_adyen'
        ),
        card_tim: this.$t(
          'pages.transactions.all.table.subtable.payment_types.card_tim'
        )
      }
    }
  },
  computed: {
    locale() {
      return this.$store.getters['Config/getLocale']
    }
  },
  created() {
    this.$emitter.on('close-receipts', this.closeDialog)
  },
  methods: {
    getQuantity(qty) {
      return this.$formatNumber(qty, { dynamicPrecision: true }) || '--'
    },
    getCart() {
      const totalGross = this.getTotal('amount_total_gross', false) || 0
      const totalTax = this.getTotal('tax_amount_total', false) || 0
      const currency = this.row.currency || this.row.items[0].currency

      if (!currency) {
        return this.$message({
          type: 'warning',
          message: this.$t(
            'pages.transactions.all.table.subtable.warnings.missing_currency'
          )
        })
      }

      return {
        amount: {
          gross: totalGross,
          net: totalGross - totalTax
        },
        currency: this.row.currency || 'EUR',
        tax: totalTax
      }
    },
    getLines() {
      const lines = []

      if (this.row.code) {
        lines.push({
          ...this.row.code,
          caption: 'tillhub.de'
        })
      }

      if (
        safeGet(this.row, 'status.signature.caption') &&
        safeGet(this.row, 'status.signature.data')
      ) {
        lines.push({
          ...pick(this.row.status.signature, ['caption', 'data']),
          format: 'qr'
        })
      }
      return lines
    },
    receiptsValid() {
      const { receipts } = this.row
      return (
        receipts && receipts.length && receipts.every((receipt) => receipt.text)
      )
    },
    openReceiptEmailer() {
      this.receiptEmailerOpen = true
    },
    closeReceiptEmailer() {
      this.receiptEmailerOpen = false
    },
    openReceiptViewer() {
      this.$emit('open-current-receipts', {
        receipts: this.row.receipts,
        receiptTitle: this.row.id,
        resourceId: this.row.id
      })
    },
    closeReceiptViewer() {},
    getTotal(name, shouldFormat = true) {
      if (
        !this.row.items ||
        !this.row.items.length ||
        this.row.items.some((item) => !item.currency)
        // TODO: need to handle case when items do not have same currency
      )
        return shouldFormat ? '--' : null

      const sum = this.row.items.reduce((acc, item) => {
        return acc + (item[name] || 0)
      }, 0)

      return shouldFormat
        ? this.localizeCurrency(sum, this.row.items[0].currency)
        : sum
    },
    localizeCurrency(amount, currency) {
      if (!currency) return '--'
      return this.$formatCurrency(amount || 0, currency)
    }
  }
}
</script>

<style scoped>
.sub-table-row {
  border-top: 1px solid var(--border-color);
  padding-top: 0.5rem;
}

.actions-column {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.vat-rate {
  color: grey;
  font-weight: normal;
  margin-left: 0.2rem;
}
</style>
